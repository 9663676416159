/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.block-container {
  position: relative;
}
.block-mask {
  position: absolute;
  z-index: 990;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  pointer-events: none;
  background-color: #e8e8e8;
  border-radius: 4px;
  border: 1px solid #bfbfbf;
  opacity: 0.8;
}
.block-mask .block-message {
  text-align: center;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  position: absolute;
  font-size: 2em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
