/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-task-block {
  margin-bottom: 16px;
  margin-left: calc(20% / 2);
}
.page-action-right {
  float: right;
  margin-right: 30px;
  margin-bottom: 8px;
}
.input-pane {
  padding: 12px 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  margin-bottom: 16px;
}
.input-pane > label {
  line-height: 32px;
  vertical-align: middle;
  margin-right: 2px;
  color: #1890ff;
}
.service-detail-title {
  margin-top: 15px;
}
.service_repair-form {
  padding: 16px 32px;
}
