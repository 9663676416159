/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.delivery-detail {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
}
.delivery-detail .delivery-col {
  margin: 0 16px 8px 0;
}
.delivery-detail label {
  color: #1890ff;
  margin-right: 2px;
}
.delivery-detail label::after {
  content: ' :';
}
.delivery-detail .delivery-input {
  display: inline-block;
}
.mobile .delivery-detail .delivery-input {
  display: block;
}
.delivery-detail-title {
  text-decoration: underline;
  color: #14837B;
  margin-bottom: 4px;
  width: 100%;
}
.delivery-type {
  margin-bottom: 12px;
}
