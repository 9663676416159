/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service_repair-row {
  margin-bottom: 12px;
}
.service_repair-row label {
  color: #1890ff;
  margin-right: 2px;
}
.service_repair-input-block {
  display: inline-block;
}
.service_repair-column {
  display: inline-block;
  margin-bottom: 12px;
}
.service_repair-column.column1 {
  width: 300px;
}
.mobile .service_repair-column {
  display: block;
  width: auto;
}
